import axios from "axios";
import React from "react";
import { TiWarning } from "react-icons/ti";
import ReactLoading from "react-loading";

const Confirm = ({ setConfirm, siswa, setAlert }) => {
  const [loading, setLoading] = React.useState(false);
  const [pernyataan1, setpernyataan1] = React.useState();
  const [pernyataan2, setpernyataan2] = React.useState();
  const [msg, setMsg] = React.useState();

  React.useEffect(() => {
    setMsg("");
  }, [pernyataan1, pernyataan2]);

  const hendleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const putPernyataan = await axios.put(
        `https://api.smknegeri4sijunjung.sch.id/graduation/?nisn=${siswa.nisn}`,
        { setpernyataan1: pernyataan1, setpernyataan2: pernyataan2 }
      );
      setLoading(false);
      setConfirm(false);
      setAlert(true);
    } catch (error) {
      setMsg(error.response.data.message);
      setLoading(false);
    }
  };

  return (
    <div className="fixed top-0 z-40 flex flex-col w-full h-full items-center justify-center">
      {loading && (
        <div className="fixed z-50 flex w-full h-full justify-center items-center">
          <ReactLoading
            type="spin"
            color="#00ffff"
            height="150px"
            width="150px"
          />
        </div>
      )}
      <div className="absolute -z-50 bg-black opacity-20 h-full w-full"></div>
      <div className="border w-[460px] px-3 rounded-md bg-white">
        <button
          className="text-2xl font-bold"
          onClick={() => setConfirm(false)}
        >
          x
        </button>
        <form action="post" onSubmit={hendleSubmit}>
          <div className="flex flex-col w-full h-full items-center gap-1 py-10">
            <TiWarning size={"80px"} className="text-yellow-400" />
            <p className="text-red-500 text-xs">{msg}</p>

            <span className="h-[0.6px] w-full bg-gray-400"></span>

            <div className="flex w-full gap-1 h-10 justify-start items-center">
              <input
                type="checkbox"
                value={`Saya ${siswa.nama} bersedia untuk tidak melakukan coret-coret/Konvoi`}
                name="persaratan1"
                onClick={(e) =>
                  setpernyataan1(e.target.checked ? e.target.value : "")
                }
              />
              <p>
                <span className="font-extrabold">"Jika"</span> Saya Lulus, saya
                sanggup tidak melakukan coret-coret/Konvoi
              </p>
            </div>

            <span className="h-[0.6px] w-full bg-gray-400"></span>

            <div className="flex gap-1 h-10 justify-start items-center">
              <input
                type="checkbox"
                value={`Saya ${siswa.nama} bersedia untuk mengambil ijazah maksimal 6 bulan setelah kelulusan`}
                name="persaratan2"
                onClick={(e) =>
                  setpernyataan2(e.target.checked ? e.target.value : "")
                }
              />
              <p>
                <span className="font-extrabold">"Jika"</span> Saya Lulus, saya
                sanggup mengambil ijazah maksimal 6 bulan setelah kelulusan
              </p>
            </div>

            <span className="h-[0.6px] w-full bg-gray-400"></span>

            <p className="text-sm mt-5 font-bold">Keterangan :</p>
            <p className="text-sm text-center font-bold text-gray-400">
              Hy {siswa.nama} Silahkan ceklis persaratan di atas untuk melihat
              hasil kelulusan kamu
            </p>
            <button
              type="submit"
              className="bg-orange-500 mt-5 py-1 px-3 rounded-md text-white hover:bg-gray-800"
            >
              Confirm
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Confirm;
