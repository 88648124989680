import React from "react";
import Alert from "../component/Alert";
import Home from "../component/Home";
import axios from "axios";
import Confirm from "../component/Confirm";
import "react-toastify/dist/ReactToastify.css";
import ReactLoading from "react-loading";

const Dashboard = () => {
  const [loading, setLoading] = React.useState(false);
  const [confirm, setConfirm] = React.useState(false);
  const [alert, setAlert] = React.useState(false);
  const [siswa, setSiswa] = React.useState();
  const [nisn, setNisn] = React.useState("");
  const [msg, setMsg] = React.useState();

  const hendleChange = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const response = await axios.post(
        "https://api.smknegeri4sijunjung.sch.id/graduation",
        {
          nisn: nisn,
        }
      );
      setSiswa(response.data.data);
      setLoading(false);
      setConfirm(true);
    } catch (error) {
      setMsg(error.response.data.message);
      setLoading(false);
    }
  };
  return (
    <div>
      {loading && (
        <div className="fixed z-50 flex w-full h-full justify-center items-center">
          <ReactLoading
            type="spin"
            color="#00ffff"
            height="150px"
            width="150px"
          />
        </div>
      )}
      {alert && <Alert siswa={siswa} setAlert={setAlert} />}

      {confirm && (
        <Confirm setAlert={setAlert} siswa={siswa} setConfirm={setConfirm} />
      )}

      <Home
        setMsg={setMsg}
        msg={msg}
        setNisn={setNisn}
        nisn={nisn}
        hendleChange={hendleChange}
      />
    </div>
  );
};

export default Dashboard;
