import React from "react";
import { FcGraduationCap } from "react-icons/fc";
import ReactLoading from "react-loading";

const Alert = ({ siswa, setAlert }) => {
  const [loading, setLoading] = React.useState(true);
  setTimeout(() => {
    setLoading(false);
  }, 1000);
  return (
    <div>
      {loading && (
        <div className="fixed z-50 flex w-full h-full justify-center items-center">
          <ReactLoading
            type="spin"
            color="#00ffff"
            height="150px"
            width="150px"
          />
        </div>
      )}
      {!loading && (
        <div className="fixed top-0 z-40 flex flex-col w-full h-full items-center justify-center">
          <div className="absolute -z-50 bg-black opacity-20 h-full w-full"></div>
          <div className="border w-[460px] px-3 rounded-md bg-white">
            <button
              className="text-2xl font-bold"
              onClick={() => setAlert(false)}
            >
              x
            </button>
            <div className="flex flex-col w-full h-full items-center py-10">
              <FcGraduationCap size={"140px"} className="text-green-400" />
              <table className="w-full text-center text-sm font-light">
                <thead className="border-b font-medium dark:border-neutral-500">
                  <tr>
                    <th scope="col" className="">
                      Program Keahlian
                    </th>
                    <th scope="col" className="px-6 py-2">
                      Nama
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="border-b dark:border-neutral-500">
                    <td className="whitespace-nowrap px-6 py-2">
                      {siswa.kompetensi_keahlian}
                    </td>
                    <td className="whitespace-nowrap px-6 py-2">
                      {siswa.nama}
                    </td>
                  </tr>
                </tbody>
              </table>
              <p className="text-base mt-5 font-bold">Status</p>
              <p className="text-xl font-bold text-blue-500">
                {siswa.keterangan}
              </p>
              <button
                className="bg-orange-500 mt-5 py-1 px-3 rounded-md text-white hover:bg-gray-800"
                onClick={() => setAlert(false)}
              >
                Tutup
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Alert;
