import React from "react";

const Home = ({ setNisn, msg, setMsg, nisn, hendleChange }) => {
  React.useEffect(() => {
    setMsg("");
  }, [nisn]);
  return (
    <div className="h-screen w-full flex justify-center items-center">
      <div className="border-2 rounded-md p-10 w-[70%] sm:w-[65%] md:w-[55%] lg:w-[40%] xl:w-[30%]">
        <div className="flex flex-col items-center">
          <img
            className="w-28"
            src={require("../assets/img/logoLogin.png")}
            alt=""
          />
          <h1 className="md:text-2xl font-bold text-center">
            SMKN 4 SIJUNJUNG
          </h1>
          <p className="my-5 text-gray-600 text-center">
            Welcome To Graduation Examine
          </p>
          <p className="text-center mb-2 text-red-500">{msg}</p>
        </div>
        <form onSubmit={hendleChange} className="flex flex-col w-full gap-2">
          <label htmlFor="">
            NISN<span className="text-red-500">*</span>
          </label>
          <input
            className="rounded-md border-b border-orange-500 h-7"
            type="number"
            name="nisn"
            value={nisn}
            onChange={(e) => setNisn(e.target.value)}
          />
          <p className="text-start w-full mb-2 text-xs">
            *Ket: Untuk Cek kelulusan silahkan masukan NISN
          </p>
          <div className="flex flex-col items-center justify-center mt-8">
            <button
              type="submit"
              className="addChatFa flex hover:gap-2 justify-center items-center w-[100px] h-[40px] border border-orange-500 text-gray-900 rounded-md hover:border-none hover:bg-orange-500 duration-200 hover:text-white font-semibold"
            >
              Check
            </button>
            <p className="text-xs py-7 text-gray-500">
              ----------------OR----------------
            </p>
            <button className="addChatFa flex hover:gap-2 justify-center items-center w-[150px] h-[40px] border border-orange-500 text-gray-900 rounded-md hover:border-none hover:bg-orange-500 duration-200 hover:text-white font-semibold">
              <a href="https://smknegeri4sijunjung.sch.id/">Back to Website</a>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Home;
